import { AuthenticationService, Tenant as BillettLukeTenant, OrderTicketWizard, ticketCounterApi } from "@ruter-as/billettluke-frontend";
import { apiClient } from "@ruter-as/web-components-and-tools";
import { Container } from "@ruter-ds/rds-components";
import React, { useEffect, useState } from "react";
import { hasValidAgreement } from "src/common/agreements";
import { getExternalContactUsUrl } from "src/common/getUrls";
import { useAuthContextAuthenticated } from "../../../AuthContext";
import { Tenant, getTenant } from "../../../types/Tenant";
import { getBlacklistedProducts } from "../../../types/ticketCounter/ticketCounterBlacklist";
import "./TicketCounterOrder.scss";

const TicketCounterOrder: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const ticketCounterAgreements = authContext.userData.selectedCompany.ticketCounterAgreements;
  const agreementNumber = ticketCounterAgreements && ticketCounterAgreements[0].id;
  const companyId = authContext.userData.selectedCompany.id;
  const tenant = getTenant();
  const visitAddress = tenant === Tenant.Ruter ? authContext.userData.selectedCompany.addresses.visit : undefined;
  const localTenant = getTenant();
  const [allowTicketAdminsToUpdateInvoiceReferences, setAllowTicketAdminsToUpdateInvoiceReferences] = useState<boolean>(false);
  let blacklistedProducts: string[] = [];


  useEffect(() => {
    if (companyId) {
      const fetch = async () => {
        const settingsResponse = await apiClient.request(ticketCounterApi.company.getSettings(companyId));
        if (settingsResponse.type === "success") {
          setAllowTicketAdminsToUpdateInvoiceReferences(settingsResponse.result.allowTicketAdminsToUpdateInvoiceReferences);
        } else {
          setAllowTicketAdminsToUpdateInvoiceReferences(() => {
            throw settingsResponse.error;
          });
        }

      };

      fetch();
    }
  }, [companyId]);

  const ticketCounterTenant = (() => {
    switch (localTenant) {
      case Tenant.Ruter:
        blacklistedProducts = getBlacklistedProducts(Tenant.Ruter);
        return BillettLukeTenant.Ruter;
      case Tenant.Brakar:
        return BillettLukeTenant.Brakar;
      case Tenant.Akt:
        blacklistedProducts = getBlacklistedProducts(Tenant.Akt);
        return BillettLukeTenant.AKT;
      default:
        return BillettLukeTenant.Ruter;
    }
  })();

  if (!hasValidAgreement(ticketCounterAgreements)) {
    throw new Error("Missing valid ticket counter agreement");
  }

  if (!authContext) {
    throw new Error("AuthContext can not be null");
  }

  if (!agreementNumber) {
    throw new Error("agreementnumber must have value");
  }

  if (!companyId) {
    throw new Error("companyId must exist");
  }

  const authService: AuthenticationService = {
    getAccessToken: async () => {
      const token = await authContext.getAccessToken();
      if (!token) {
        throw new Error("could not find token");
      }
      return token;
    },
  };

  const errorLink = tenant === Tenant.Ruter ? "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/" : getExternalContactUsUrl();

  return (
    <Container width="s" className="components-ticketcounter-order">
      <OrderTicketWizard
        consumer="company"
        agreementNumber={agreementNumber}
        tenant={ticketCounterTenant}
        authService={authService}
        errorLink={errorLink}
        companyId={companyId}
        quickCheckoutEnabled={true}
        blacklistedProducts={blacklistedProducts}
        allowCreateInvoiceReference={allowTicketAdminsToUpdateInvoiceReferences}
        deliveryAddress={visitAddress ? {
          addressLine1: visitAddress.line1,
          addressLine2: visitAddress.line2,
          zipCode: visitAddress.postCode,
          area: visitAddress.city,
        } : undefined}
      />
    </Container>
  );
};

export default TicketCounterOrder;
