import React from "react";

interface Props {
  pickupCode: string | null
  "data-label"?: string
}

const PickupCodeColumn: React.FC<Props> = (props) => {
  const dataLabel = props["data-label"]; // eslint-disable-line

  return (
    <td data-label={dataLabel}>{props.pickupCode}</td>
  );
};

export default PickupCodeColumn;