import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface FreeTicketInfoLanguageResource {
  title: string;
  privacyText: string;
  purposeTitle: string;
  purposeText: string;
  purposeBullet1: string;
  purposeBullet2: string;
  purposeBullet3: string;
  legalTitle: string;
  legalText: string;
  dataTreatedTitle: string;
  dataTreatedText: string;
  dataTreatedBullet1: string;
  dataTreatedBullet2: string;
  dataTreatedBullet3: string;
  dataTreatedText2: string;
  accessTitle: string;
  accessText1: string;
  accessLink: string;
  accessText2: string;
  storageTitle: string;
  storageText: string;
  storageBullet1: string;
  storageBullet2: string;
}

export const freeTicketInfoLanguageResource: LanguageResource<FreeTicketInfoLanguageResource> = {
  nb: {
    title: "Fribillett og personvern",
    privacyText:
      "Fribilletten er en personlig billett. Det er derfor nødvendig å innhente og lagre personopplysninger om deg som skal ha fribillett. Dette følger av avtalen mellom deg og din bedrift.",
    purposeTitle: "Formål",
    purposeText: "Ruter behandler personopplysninger for å kunne tilby deg fribillett, noe som inkluderer følgende:",
    purposeBullet1: "Sende hentekode for fribillett på SMS",
    purposeBullet2: "Opprette personlig billett som kan kontrolleres og sjekkes mot legitimasjon",
    purposeBullet3:
      "Gi bedriften din oversikt over ansatte, familiemedlemmer og pensjonister som har billett, slik at bedriften kan beregne riktig beskatningsgrunnlag",
    legalTitle: "Rettslig grunnlag",
    legalText: "Oppfylle arbeidsavtalen med deg og din rett til fribillett.",
    dataTreatedTitle: "Dette behandler vi",
    dataTreatedText: "Vi behandler følgende opplysninger om deg og familiemedlemmer som har billett:",
    dataTreatedBullet1: "Kundeopplysninger: Mobiltelefonnummer, fødselsdato og navn",
    dataTreatedBullet2:
      "Salgsopplysninger: Billettype og hvilke soner billetten er gyldig, samt økonomiske transaksjoner i forbindelse med fakturering av bedriften og beregning av beskatningsgrunnlag",
    dataTreatedBullet3: "Bedriftsopplysninger: Ansattnummer",
    dataTreatedText2: "Vi behandler opplysningene for å oppfylle din avtale med bedriften om fribillett.",
    accessTitle: "Tilgang og bruk",
    accessText1:
      "Tilgangen til opplysninger om deg er begrenset. Du har rett til innsyn i og retting, dataportabilitet og sletting av opplysninger som er lagret om deg.",
    accessLink: "Les mer om dine rettigheter.",
    accessText2: "Vi utleverer ikke personopplysningene dine til en tredjepart så lenge vi ikke blir pålagt det av offentlige myndigheter.",
    storageTitle: "Lagring og sletting",
    storageText: "Dine opplysninger blir lagret og slettet etter følgende kriterier:",
    storageBullet1: "Kundeopplysningene blir lagret så lenge du har fribillett og 3 måneder etterpå",
    storageBullet2: "Salgsopplysninger lagres i 5 år etter at billetten er utløpt",
  },
  en: {
    title: "Free-ticket and privacy",
    privacyText:
      "A Free-ticket is a personal ticket. It is therefore necessary to obtain and store information regarding you as a ticket holder.",
    purposeTitle: "Purpose",
    purposeText: "Ruter obtain and process personal information in order to offer free-ticket services:",
    purposeBullet1: "Send pickup code via SMS",
    purposeBullet2: "Create a personal ticket that can be controlled and verified with a personal id",
    purposeBullet3: "Give your company an overview of its tickets and ticket holders",
    legalTitle: "Legal justification",
    legalText: "Comply with the work agreement between you and your company, which states your right to a free-ticket.",
    dataTreatedTitle: "Data that we store",
    dataTreatedText: "We store and process the folling information for all ticket holders:",
    dataTreatedBullet1: "Custoner information: Mobile number, birth date and name",
    dataTreatedBullet2: "Sales information: ticketype, zones, economic transaction data",
    dataTreatedBullet3: "Company information: Employee number",
    dataTreatedText2:
      "We obtain and process information about you in order to fullfill the agreement between you and your company that grants you a free-ticket.",
    accessTitle: "Access and ussage",
    accessText1:
      "Access to information about you is restricted. You have a right to access, retrieve and to demand deletion of the information about you.",
    accessLink: "Read more about your rights.",
    accessText2: "We do not share your information with third parties unless instructed to do so by national government.",
    storageTitle: "Storage and deletion",
    storageText: "Your information is stored and deleted according to the following criteria:",
    storageBullet1:
      "Customer information is stored as long as you have a free-ticket and up to three months after cancellation of your free-ticket",
    storageBullet2: "Sales information is stored for 5 years after the the free-ticket is cancelled",

  },
};