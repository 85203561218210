import { InAppInvoiceEligible, InvoiceReference, ticketCounterApi } from "@ruter-as/billettluke-frontend";
import { apiClient, Button, ButtonGroup, DropdownFormInput, FormGroup, Modal, PhoneFormInput, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import parsePhoneNumberFromString from "libphonenumber-js";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import { CancelButton } from "src/components/common/buttons";
import ticketCounterWhiteListLanguageResource from "./lang-resource";

interface Props {
  handleClose: () => void;
  handleSuccess: () => void;
  invoiceRefOptions: InvoiceReference[]
  editUser: InAppInvoiceEligible
}

interface FormData {
  name: string
  phoneNumber: string
  invoiceRef: string
}

const EditEligibleUser: React.FC<Props> = ({ editUser, handleClose, handleSuccess, invoiceRefOptions }) => {
  const lang = useLanguageResource(ticketCounterWhiteListLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues: {
      invoiceRef: editUser.invoiceReferenceId,
      name: editUser.name,
      phoneNumber: `${editUser.phoneCountryCode} ${editUser.phone}`,
    },
  });

  const onSubmit = async (data: FormData) => {

    setLoading(true);
    const phone = parsePhoneNumberFromString(data.phoneNumber);
    if (!phone || !phone.isValid()) {
      throw new Error("invalid phone number should be impossible as it is validated by the form");
    }

    const response = await apiClient.request(ticketCounterApi.inAppInvoiceEligible.editUser(editUser.id,
      {
        invoiceReferenceId: data.invoiceRef,
        name: data.name,
        phone: phone.nationalNumber.toString(),
        phoneCountryCode: `+${phone.countryCallingCode}`,
      }));

    if (response.type === "success") {
      handleSuccess();
    } else {
      setLoading(() => {
        throw response.error;
      });
    }
  };

  return (
    <Modal isOpen title={lang.editUser.replace("$p1$", editUser.name)} handleClose={() => { handleClose(); }} >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TextFormInput name="name" required label={lang.name} />
          <PhoneFormInput name="phone" required label={lang.phoneNumber} />
          <DropdownFormInput name="invoiceRef" required label={lang.invoiceReference} >
            {invoiceRefOptions.map(option => <option value={option.id} key={option.id}>{option.reference}</option>)}
          </DropdownFormInput>
          <FormGroup>
            <ButtonGroup>
              <Button text={formLang.save} type="submit" variant="primary" loading={loading} />
              <CancelButton onClick={() => { handleClose(); }} />
            </ButtonGroup>
          </FormGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditEligibleUser;