import { Button, ButtonGroup, Container, EmailFormInput, IconButton, Message, PhoneFormInput, Table, TextFormInput, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as sentry from "@sentry/react";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import successAlert from "src/common/toastr";
import AgreementTypeColumn from "src/components/common/Table/AgreementTypeColumn/AgreementTypeColumn";
import EditCompanyAddress, { EditAddressesFormFields } from "src/components/common/modal/editCompanyAddress/EditCompanyAddress";
import AddressesCard from "src/components/companyInfo/AddressesCard";
import CompanyCard from "src/components/companyInfo/CompanyCard";
import { AgreementInvoicingInterval } from "src/types/AgreementInvoicingInterval";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { Agreement, CompanyAddresses } from "src/types/Company";
import { CompanyPrefill, mapCompanyPrefill } from "src/types/CompanyPrefill";
import { CompanyType } from "src/types/CompanyType";
import { Tenant, getTenant } from "src/types/Tenant";
import EditAgreementModal from "../company/EditAgreementModal";
import "./CreateCompany.scss";
import createCompanyLanguageResource from "./languageResource";

interface OrgNumberForm {
  orgNumber: string;
}

interface CompanyAddressPostContract {
  addressLine1: string;
  addressLine2: string;
  countryCode: string;
  postArea: string;
  postCode: string;
}

interface CompanyAgreementPostContract {
  subType: AgreementSubTypeId;
  invoiceReference: string;
  invoicingInterval: AgreementInvoicingInterval;
}

interface CompanyPostContract {
  company: {
    id?: string;
    organisationNumber: string;
    name: string;
    billAddress: CompanyAddressPostContract;
    postAddress: CompanyAddressPostContract;
    visitAddress: CompanyAddressPostContract;
  }
  agreements: Array<CompanyAgreementPostContract>,
  user: {
    email: string
    phone: string
    phoneCountryCode: string
    firstName: string
    lastName: string
  },
  signee?: {
    name: string;
    email: string;
  }
}

interface CompanyForm {
  customerNumber: string;
  userEmail: string
  userPhone: string
  userFirstName: string;
  userLastName: string;
  signeeEmail: string;
  signeeName: string;
}

const getDefaultAgreements = (): Array<Agreement> => {
  const ticketOfficeAgreement: Agreement = {
    id: "1",
    invoiceReference: "",
    invoicingInterval: AgreementInvoicingInterval.MONTHLY_AT_END,
    paymentDeadline: 30,
    subTypeId: AgreementSubTypeId.TICKETOFFICE,
    fromDate: null,
    endDate: null,
  };

  const companyAgreement: Agreement = {
    id: "2",
    invoiceReference: "",
    invoicingInterval: AgreementInvoicingInterval.MONTHLY_AT_END,
    paymentDeadline: 30,
    subTypeId: AgreementSubTypeId.SUBSCRIPTIONS,
    fromDate: null,
    endDate: null,
  };

  const freeTicketAgreement: Agreement = {
    id: "3",
    invoiceReference: "",
    invoicingInterval: AgreementInvoicingInterval.MONTHLY_AT_END,
    paymentDeadline: 30,
    subTypeId: AgreementSubTypeId.FREETICKET,
    fromDate: null,
    endDate: null,
  };

  const tenant = getTenant();

  switch (tenant) {
    case Tenant.Ruter: return [ticketOfficeAgreement, companyAgreement];
    case Tenant.Akt: return [ticketOfficeAgreement];
    case Tenant.Brakar: return [ticketOfficeAgreement, freeTicketAgreement];
  }
};

const CreateCompany: React.FC = () => {
  const lang = useLanguageResource(createCompanyLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const orgNumberFormMethods = useForm<OrgNumberForm>({ defaultValues: { orgNumber: "" } });
  const companyFormMethods = useForm<CompanyForm>({});
  const [prefillErrorCode, setPrefillErrorCode] = useState<false | number>(false);
  const [submitCompanyError, setSubmitCompanyError] = useState(false);
  const [submittingOrgNumber, setSubmittingOrgNumber] = useState(false);
  const [submittingCompany, setSubmittingCompany] = useState(false);
  const [prefillCompany, setCompany] = useState<CompanyPrefill | null>(null);
  const [editAddresses, setEditAddresses] = useState(false);
  const [companyAddresses, setCompanyAddresses] = useState<CompanyAddresses | null>(null);
  const [companyAgreements, setCompanyAgreements] = useState<Array<Agreement>>(getDefaultAgreements());
  const navigate = useNavigate();
  const [editAgreement, setEditAgreement] = useState<Agreement | null>(null);
  const tenant = getTenant();

  const requireSignee = tenant === Tenant.Ruter;
  const showCustomerNumber = tenant !== Tenant.Ruter;

  const openNewAgreementModal = () => {
    setEditAgreement({
      endDate: null,
      fromDate: null,
      invoiceReference: "",
      invoicingInterval: AgreementInvoicingInterval.MONTHLY_AT_END,
      paymentDeadline: 30,
      id: "",
      subTypeId: AgreementSubTypeId.SUBSCRIPTIONS,
    });
  };

  const submitOrgNumber = async (values: OrgNumberForm) => {
    setSubmittingOrgNumber(true);
    setPrefillErrorCode(false);
    const response = await apiClient.get(`/onboarding/company/create-prefill/${values.orgNumber}`, mapCompanyPrefill);

    if (response.type === "success") {
      setCompany(response.result);
      setCompanyAddresses(response.result.addresses);
    } else if (response.type === "HttpError") {
      setPrefillErrorCode(response.responseStatus);
    } else {
      setPrefillErrorCode(500);
    }

    setSubmittingOrgNumber(false);
  };

  const submitCompany = async (values: CompanyForm) => {
    if (!prefillCompany || !companyAddresses) {
      throw new Error("prefill company can not be null. This part of the code should not have been reached");
    }

    const phone = parsePhoneNumberFromString(values.userPhone);

    if (!phone || !phone.isValid()) {
      throw new Error("invalid phone number should be impossible as it is validated by the form");
    }

    const body: CompanyPostContract = {
      company: {
        id: values.customerNumber,
        organisationNumber: prefillCompany.organisationNumber,
        name: prefillCompany.name,
        billAddress: {
          addressLine1: companyAddresses.bill.line1,
          addressLine2: companyAddresses.bill.line2,
          countryCode: companyAddresses.bill.country,
          postCode: companyAddresses.bill.postCode,
          postArea: companyAddresses.bill.city,
        },
        postAddress: {
          addressLine1: companyAddresses.post.line1,
          addressLine2: companyAddresses.post.line2,
          countryCode: companyAddresses.post.country,
          postCode: companyAddresses.post.postCode,
          postArea: companyAddresses.post.city,
        },
        visitAddress: {
          addressLine1: companyAddresses.visit.line1,
          addressLine2: companyAddresses.visit.line2,
          countryCode: companyAddresses.visit.country,
          postCode: companyAddresses.visit.postCode,
          postArea: companyAddresses.visit.city,
        },
      },
      agreements: companyAgreements.map((x) => ({ invoiceReference: x.invoiceReference, invoicingInterval: x.invoicingInterval || AgreementInvoicingInterval.MONTHLY_AT_END, subType: x.subTypeId })),
      signee: requireSignee ? {
        name: values.signeeName,
        email: values.signeeEmail,
      } : undefined,
      user: {
        email: values.userEmail,
        phone: phone.nationalNumber.toString(),
        phoneCountryCode: `+${phone.countryCallingCode}`,
        firstName: values.userFirstName,
        lastName: values.userLastName,
      },
    };

    setSubmittingCompany(true);
    setSubmitCompanyError(false);

    const result = await apiClient.post("/onboarding/admin-onboard", body);
    if (result.type === "success") {
      successAlert(lang.companySubmitConfirmationMessage);
      navigate("/bedrifter");
    } else {
      sentry.captureException(result.error);
      setSubmitCompanyError(true);
    }
    setSubmittingCompany(false);
  };

  const updateAddresses = (data: EditAddressesFormFields) => {
    setEditAddresses(false);
    setCompanyAddresses({
      bill: {
        city: data.billCity,
        country: "NO",
        line1: data.billAddressLine1,
        line2: data.billAddressLine2,
        postCode: data.billPostCode,
      },
      post: {
        city: data.postCity,
        country: "NO",
        line1: data.postAddressLine1,
        line2: data.postAddressLine2,
        postCode: data.postPostCode,
      },
      visit: {
        city: data.visitCity,
        country: "NO",
        line1: data.visitAddressLine1,
        line2: data.visitAddressLine2,
        postCode: data.visitPostCode,
      },
    });
  };

  const getErrorCodeParameters = (statusCode: number): {
    type: "invalid" | "generic-error" | "not-found" | "not-suitable",
    text: string,
    skin: "warning" | "danger"
  } => {
    switch (statusCode) {
      case 400: return { text: lang.orgNumberInvalidError, type: "invalid", skin: "warning" };
      case 404: return { text: lang.orgNumberNotFoundError, type: "not-found", skin: "warning" };
      case 409:
      case 410: return { text: lang.orgNumberUnsuitable, type: "not-suitable", skin: "warning" };
      default: return { text: lang.orgNumberGenericError, type: "generic-error", skin: "danger" };

    }
  };

  const resetCompany = () => {
    setCompany(null);
    setCompanyAddresses(null);
  };

  const addOrUpdateAgreement = (agreement: Agreement) => {
    if (agreement.id) {
      setCompanyAgreements(companyAgreements.map(x => {
        if (x.id === agreement.id) {
          return agreement;
        } else {
          return x;
        }
      }));
    } else {
      const id = Math.max(...companyAgreements.map(x => parseInt(x.id))) + 1;
      setCompanyAgreements([...companyAgreements, {
        ...agreement,
        id: id.toString(),
      }]);
    }
    setEditAgreement(null);
  };

  const removeAgreement = (agreement: Agreement) => {
    setCompanyAgreements(companyAgreements.filter(x => x.id !== agreement.id));
  };

  const renderAgreementRow = (agreement: Agreement) => {
    return (
      <tr key={agreement.id}>
        <td>{agreement.invoiceReference}</td>
        <td><AgreementTypeColumn agreements={[agreement]} /></td>
        <td>
          <div className="actions">
            <IconButton variant="PencilIcon" data-test-id="edit-agreement-button" title={lang.editAgreementButton} onClick={() => setEditAgreement(agreement)} aria-label={lang.editAgreementButton} />
            <IconButton variant="CrossIcon" data-test-id="delete-agreement-button" title={lang.deleteAgreementButton} onClick={() => removeAgreement(agreement)} aria-label={lang.deleteAgreementButton} />
          </div>
        </td>
      </tr>
    );
  };

  const renderOrgNumberError = (statusCode: number): React.ReactElement => {
    const { type, text, skin } = getErrorCodeParameters(statusCode);

    return (
      <div style={{ marginTop: "1rem" }} data-test-id="org-number-error-message" data-test-error-type={type}>
        <Message skin={skin} data-test-id="org-number-generic-error">{text}</Message>
      </div>
    );
  };

  const renderOrgNumberForm = () => {
    return (
      <Container width="xs" data-test-id="create-company-page-org-number-form">
        <h1>{lang.title}</h1>
        <FormProvider {...orgNumberFormMethods}>
          <form onSubmit={orgNumberFormMethods.handleSubmit(submitOrgNumber)}>
            <TextFormInput name="orgNumber" required numericOnly minLength={9} maxLength={9} label={lang.orgNumber} />
            {prefillErrorCode && renderOrgNumberError(prefillErrorCode)}
            <ButtonGroup>
              <Button loading={submittingOrgNumber} type="submit" variant="primary" text={lang.search} />
            </ButtonGroup>
          </form>
        </FormProvider>
      </Container>
    );
  };

  const renderNewCompanyForm = (company: CompanyPrefill, addresses: CompanyAddresses) => {
    return (
      <Container width="s" data-test-id="create-company-page-company-form" className="create-company-page">
        <h1>{lang.title}</h1>
        <CompanyCard name={company.name} orgNumber={company.organisationNumber} />
        <AddressesCard companyType={CompanyType.RUTER_CUSTOMER} companyAddresses={addresses} onChangeClick={() => { setEditAddresses(true); }} />
        <div className="agreement-header">
          <h2>{lang.agreements}</h2>
          <Button variant="primary" text={lang.newAgreement} type="button" data-test-id="new-agreement-button" onClick={openNewAgreementModal} />
        </div>
        <Table>
          <thead>
            <tr>
              <th>{lang.agreementInvoideRef}</th>
              <th>{lang.agreementType}</th>
              <th style={{ width: "100px" }}></th>
            </tr>
          </thead>
          <tbody>
            {companyAgreements.map(renderAgreementRow)}
          </tbody>
        </Table>

        <FormProvider {...companyFormMethods}>
          <form onSubmit={companyFormMethods.handleSubmit(submitCompany)}>
            {showCustomerNumber && (
              <>
                <div style={{ marginTop: "3rem" }}><h2>{lang.company}</h2></div>
                <TextFormInput required maxLength={50} name="customerNumber" label={lang.customerNumber} />
              </>
            )}

            <div style={{ marginTop: "3rem" }}><h2>{lang.userAdmin}</h2></div>
            <EmailFormInput name="userEmail" required label={formLang.email} />
            <PhoneFormInput name="userPhone" mobileOnly required label={formLang.mobile} />
            <TextFormInput name="userFirstName" required maxLength={200} label={formLang.firstName} />
            <TextFormInput name="userLastName" required maxLength={200} label={formLang.lastName} />

            <div style={{ marginTop: "3rem" }}><h2>{lang.signee}</h2></div>
            <TextFormInput disabled={!requireSignee} name="signeeName" required={requireSignee} maxLength={200} label={lang.fullName} />
            <EmailFormInput disabled={!requireSignee} name="signeeEmail" required={requireSignee} label={formLang.email} />

            {submitCompanyError && (
              <Message skin="danger" style={{ marginTop: "2rem" }} data-test-id="submit-company-error-message">
                {lang.submitCompanyError}
              </Message>
            )}
            <ButtonGroup>
              <Button type="submit" variant="primary" text={formLang.save} loading={submittingCompany} />
              <Button type="button" variant="cancel" data-test-id="cancel-button" text={formLang.cancel} onClick={resetCompany} />
            </ButtonGroup>
          </form>
        </FormProvider>

        {editAgreement && <EditAgreementModal type="callback" agreement={editAgreement} onClose={() => setEditAgreement(null)} onSave={addOrUpdateAgreement} />}
        {editAddresses && prefillCompany && <EditCompanyAddress companyType={CompanyType.RUTER_CUSTOMER} onClose={() => setEditAddresses(false)} type="callback" companyAddresses={prefillCompany?.addresses} onSave={updateAddresses} />}
      </Container>
    );
  };


  if (prefillCompany && companyAddresses) {
    return renderNewCompanyForm(prefillCompany, companyAddresses);
  } else {
    return renderOrgNumberForm();
  }
};

export default CreateCompany;