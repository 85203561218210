import { InvoiceReference, ticketCounterApi } from "@ruter-as/billettluke-frontend";
import { apiClient, ButtonGroup, FormGroup, Modal, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import ticketCounterSettingsLanguageResource from "./lang-resource";

interface Props {
  companyId: string
  onSave: (refs: InvoiceReference[]) => void
  handleClose: () => void
}

interface FormData {
  "invoice-reference": string
}

const AddInvoiceRefModal: React.FC<Props> = ({ onSave, handleClose, companyId }) => {
  const language = useLanguageResource(ticketCounterSettingsLanguageResource);
  const [loading, setLoading] = useState(false);


  const formMethods = useForm<FormData>({ defaultValues: { "invoice-reference": "" } });

  const onSaveInvoiceRef = async (data: FormData) => {
    setLoading(true);

    const saveResponse = await apiClient.request(ticketCounterApi.invoiceReference.save(companyId, data["invoice-reference"]));
    if (saveResponse.error) {
      setLoading(() => { throw saveResponse.error; });
      return;
    }

    const invoiceReferenceResponse = await apiClient.request(ticketCounterApi.invoiceReference.getAll(companyId));
    if (invoiceReferenceResponse.type === "success") {
      onSave(invoiceReferenceResponse.result);
      handleClose();
      setLoading(false);
    } else {
      setLoading(() => { throw invoiceReferenceResponse.error; });
      return;
    }
  };

  return (
    <Modal handleClose={handleClose} isOpen={true} title={language.createInv}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSaveInvoiceRef)}>
          <FormGroup>
            <TextFormInput name="invoice-reference" required label={language.invoiceRefTitle} minLength={2} maxLength={50} />
          </FormGroup>
          <ButtonGroup>
            <SubmitButton text={language.save} submitting={loading} />
            <CancelButton onClick={() => handleClose()} text={language.cancel} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};


export default AddInvoiceRefModal;
