import { LanguageResource, languageResourceBuilder, TwoParamsLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

export interface TicketZonesLanguageResource {
  zoneOne: string;
  zoneTwo: string;
  allZones: string;
  multipleZones: string;
  unknown: string;
  radiusZones: string;
  listZones: TwoParamsLanguageResourceTemplate;
}

export const ticketZonesLanguageResource: LanguageResource<TicketZonesLanguageResource> = {
  nb: {
    zoneOne: "Sone {zoneFrom}",
    zoneTwo: "{zoneFrom} og {zoneTo}",
    multipleZones: "{zoneFrom} - {zoneTo}",
    allZones: "Alle soner",
    unknown: "Ukjent",
    radiusZones: "{zoneCount} soner fra sone {zoneFrom}",
    listZones: languageResourceBuilder.twoParamTemplate("$p1$ soner ($p2$)"),
  },
  en: {
    zoneOne: "Zone {zoneFrom}",
    zoneTwo: "{zoneFrom} and {zoneTo}",
    multipleZones: "{zoneFrom} - {zoneTo}",
    allZones: "All zones",
    unknown: "Unknown",
    radiusZones: "{zoneCount} zones from zone {zoneFrom}",
    listZones: languageResourceBuilder.twoParamTemplate("$p1$ zones ($p2$)"),
  },
};