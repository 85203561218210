import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import bus from "../../../gfx/illustrasjon-buss-404.svg";
import ContactUsLink from "../contactUsLink/ContactUsLink";
import "./GenericError.scss";
import { errorBoundaryLanguageResource } from "./lang-resource";

const GenericError: React.FC = () => {
  const lang = useLanguageResource(errorBoundaryLanguageResource);

  return (
    <Container width="m" className="components-app-loadingerror" data-test-id="loading-error-page">
      <img src={bus} alt="" />
      <h1>{lang.title}</h1>
      <div>{lang.text}</div>

      <ContactUsLink text={lang.clickForContact} />
    </Container>
  );
};

export default GenericError;
