import { LanguageContextProvider } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import "core-js/actual/array/to-sorted";
import { setDefaultOptions } from "date-fns";
import { nb } from "date-fns/locale";
import moment from "moment";
import localization from "moment/locale/nb";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./AuthContext";
import TicketRequestContextProvider from "./TicketRequestContext";
import ApiClientError from "./common/apiClient/ApiClientError";
import getEnvironment from "./common/env";
import { getSubstituteDictionaryList } from "./common/substituteDictionary";
import App from "./components/app/App";
import GenericError from "./components/app/genericError/GenericError";

declare global {
  interface Window {
    Cypress: unknown;
    auth: {
      authority: string;
      // eslint-disable-next-line camelcase
      client_id: string;
      scope: string;
    };
    tenant: string;
    envName: string;
  }
}

Sentry.init({
  dsn: "https://52a72412a14055e67cfcaa089867c3ba@o268620.ingest.us.sentry.io/4508155055964160",
  environment: getEnvironment(),
  normalizeDepth: 10,
  beforeSend(event, hint) {
    const modifiedEvent = { ...event };
    if (hint?.originalException instanceof ApiClientError) {
      modifiedEvent.extra = {
        ...event.extra,
        ...hint.originalException.extra,
      };
      modifiedEvent.fingerprint = hint.originalException.fingerPrint;
    }
    if ((hint.originalException as any).fingerPrint) {
      modifiedEvent.fingerprint = [(hint.originalException as any).fingerPrint];
    }

    if ((hint.originalException as any).additionalData) {
      modifiedEvent.extra = {
        ...event.extra,
        ...(hint.originalException as any).additionalData,
      };
    }
    return modifiedEvent;
  },
});

setDefaultOptions({ locale: nb });
moment.updateLocale("nb", localization);

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={() => <GenericError />}>
    <BrowserRouter>
      <AuthContextProvider>
        <TicketRequestContextProvider>
          <LanguageContextProvider substituteDictionaryList={getSubstituteDictionaryList()}>
            <App />
          </LanguageContextProvider>
        </TicketRequestContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById("app"),
);
