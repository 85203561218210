import { AuthContextValues } from "src/AuthContext";
import { getTenant, Tenant } from "src/types/Tenant";

interface InternalUrl {
  type: "Internal",
  url: string
}

interface ExternalUrl {
  type: "External",
  url: string
}

type UrlType = ExternalUrl | InternalUrl;

export const getExternalContactUsUrl = (): string => {
  const tenant = getTenant();
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/#page=form";
    case Tenant.Brakar:
      return "https://brakar.no/kundesenter/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/kontakt-oss/";
  }
};

export const getInternalOrExternalContactUsUrl = (authContext: AuthContextValues): UrlType => {
  const tenant = getTenant();
  const externalUrl = getExternalContactUsUrl();

  if (tenant === Tenant.Ruter && authContext.authenticated) {
    return {
      type: "Internal",
      url: "/kontaktoss",
    };
  }

  return {
    type: "External",
    url: externalUrl,
  };
};

export const getTravelTermsUrl = () => {
  const tenant = getTenant();

  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/reisevilkar/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/rettigheter-og-regler/transportvilkar-for-akt/";
    case Tenant.Brakar:
      return "https://brakar.no/reiseinformasjon/transportvedtekter/";
  }
};

export const getCookiesUrl = () => {
  const tenant = getTenant();

  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/personvern/ruter.no/";
    case Tenant.Brakar:
      return "https://brakar.no/personvern/informasjonskapsler/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/rettigheter-og-regler/personvern-i-agder-kollektivtrafikk-akt/informasjonskapsler/";
  }
};

export const getPrivacyUrl = () => {
  const tenant = getTenant();

  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/personvern/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/rettigheter-og-regler/personvern-i-agder-kollektivtrafikk-akt/";
    case Tenant.Brakar:
      return "https://brakar.no/personvern/";
  }
};


