import { CompanySettings, InvoiceReference, ticketCounterApi } from "@ruter-as/billettluke-frontend";
import { apiClient, Button, ButtonGroup, CheckBoxForminput, Container, IconButton, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { SubmitButton } from "src/components/common/buttons";
import AddInvoiceRefModal from "./AddInvoiceModal";
import "./TicketCounterSettings.scss";
import ticketCounterSettingsLanguageResource from "./lang-resource";

interface FormData {
  allowTicketAdminsToUpdateInvoiceReferences: boolean;
}

const TicketCounterSettings: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;
  const language = useLanguageResource(ticketCounterSettingsLanguageResource);
  const [invoiceRefs, setInvoiceRefs] = useState<InvoiceReference[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [deleteInvoiceRef, setDeleteInvoiceRef] = useState<string>();
  const [open, setIsOpen] = useState(false);
  const [invoiceSettings, setInvoiceSettings] = useState<CompanySettings>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const formMethods = useForm<FormData>({
    defaultValues: {
      allowTicketAdminsToUpdateInvoiceReferences: invoiceSettings?.allowTicketAdminsToUpdateInvoiceReferences,
    },
  });


  useEffect(() => {
    if (companyId) {
      const fetch = async () => {
        setLoading(true);
        const [invoiceReferenceResponse, settingsResponse] = await Promise.all([
          apiClient.request(ticketCounterApi.invoiceReference.getAll(companyId)),
          apiClient.request(ticketCounterApi.company.getSettings(companyId)),
        ]);

        if (invoiceReferenceResponse.error) {
          setInvoiceRefs(() => { throw invoiceReferenceResponse.error; });
          return;
        }
        if (settingsResponse.error) {
          setInvoiceRefs(() => { throw settingsResponse.error; });
          return;
        }

        setInvoiceRefs(invoiceReferenceResponse.result);
        formMethods.setValue("allowTicketAdminsToUpdateInvoiceReferences", settingsResponse.result.allowTicketAdminsToUpdateInvoiceReferences);
        setInvoiceSettings(settingsResponse.result);

        setLoading(false);
      };
      fetch();
    }
  }, [companyId, formMethods]);

  const onRemoveInvoiceRef = async (refId: string) => {
    setDeleteInvoiceRef(refId);
    const response = await apiClient.request(ticketCounterApi.invoiceReference.deleteInvoiceRef(refId));
    if (response.type === "success") {
      if (invoiceRefs.length > 0) {
        setInvoiceRefs((prev) => [...prev.filter((item) => item.id != refId)]);
      }
      setDeleteInvoiceRef(undefined);
    } else {
      setInvoiceRefs(() => {
        throw response.error;
      });
    }
  };

  const handleInvoiceSettingsSubmit = async (data: FormData) => {
    if (!companyId) {
      throw new Error("Missing companyId");
    }

    setSubmitLoading(true);
    const allowTicketAdminsToUpdateInvoiceReferences = data.allowTicketAdminsToUpdateInvoiceReferences;
    const response = await apiClient.request(ticketCounterApi.company.updateSettings(companyId, allowTicketAdminsToUpdateInvoiceReferences));

    if (response.type === "success") {
      setTimeout(() => { setSubmitLoading(false); }, 300);
    } else {
      setSubmitLoading(() => {
        throw response.error;
      });
    }
  };


  return (
    <>
      <Container width="s" className="components-ticket-info-ticketinfo">
        <h1>{language.title}</h1>
        {invoiceSettings &&
          <div data-test-id="ticket-counter-settings">
            <h2>{language.invoiceSettings}</h2>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(handleInvoiceSettingsSubmit)}>
                <CheckBoxForminput name="allowTicketAdminsToUpdateInvoiceReferences" label={language.allowTicketAdminToUpdateInvoiceReferencesText} />
                <ButtonGroup>
                  <SubmitButton text={language.save} submitting={submitLoading} />
                </ButtonGroup>
              </form>
            </FormProvider>
          </div>}
        <div data-test-id="ticket-counter-invoicerefs">
          <div className="label">
            <h2>{language.invoices}</h2>
            <Button type="button" variant="generic" data-test-id="new-invoice-reference-button" onClick={() => setIsOpen(true)} text={language.createInvButton} />
          </div>
          <Table loading={loading}>
            <thead>
              <tr>
                <th scope="col">{language.invoiceRefTitle}</th>
                <th scope="col" className="actions">{language.actions}</th>
              </tr>
            </thead>
            <tbody>
              {invoiceRefs.map((item) => (
                <tr key={item.id}>
                  <td>{item.reference}</td>
                  <td>
                    <IconButton onClick={() => onRemoveInvoiceRef(item.id)} variant="TrashIcon" loading={deleteInvoiceRef === item.id} title={language.deleteButton} aria-label={language.deleteButton} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
      {open && companyId && <AddInvoiceRefModal onSave={(refs: InvoiceReference[]) => { setInvoiceRefs(refs); setIsOpen(false); }} handleClose={() => setIsOpen(false)} companyId={companyId} />}
    </>
  );
};



export default TicketCounterSettings;
